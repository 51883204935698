// Almost entirely inspired by this:
// https://mikerogers.io/2019/09/16/using-actioncable-and-stimulus-to-remotely-update-partials-in-rails-6
//
// app/javascript/controllers/realtime_partial_controller.js
// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This realtime-partial controller will update partials via ActionCable.
//
// <div data-controller="realtime-partial" data-realtime-partial-key="A friendly name for your partial">
//   <p>Any HTML here</p>
// </div>

import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["autoscroll"]
  initialize () {
    // Is run first. In this case we don't need to worry about anything.
  }

  connect() {
    let realtimePartialController = this;

    this.subscription = consumer.subscriptions.create(
      {
        channel: "RealtimePartialChannel",
        key: this.data.get("key")
      },
      {
        connected() {
          console.log("Connected to the realtime partial channel.")
          // Called when the subscription is ready for use on the server
          realtimePartialController.enableAutoScroll();
          if (realtimePartialController.autoscrollTarget) {
            window.onscroll = realtimePartialController.debounce(
              realtimePartialController.enableAutoScroll, 200
            );
          }
          // realtimePartialController.refreshCheck()
        },
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
        received(data) {
          let shouldScroll = realtimePartialController.windowScrolledToBottom();

          realtimePartialController.renderPartial(data);

          if (shouldScroll == true) {
            realtimePartialController.enableAutoScroll();
            realtimePartialController.scrollToBottom()
          }
        }
      }
    );
  }

  disconnect() {
    // sessionStorage.setItem('lastPage', location.href);
    this.subscription.unsubscribe();
  }

  teardown() {
    sessionStorage.setItem('lastPage', null);
  }

  renderPartial(data) {
    let newBody = this._parseHTMLResponse(data['body']);

    // Replace all data-turbolinks-permanent elements in the body with what was there
    // previously. This is useful for elements the user might interact with, such
    // as forms or dropdowns.
    let permanentNodes = this.element.querySelectorAll("[id][data-turbolinks-permanent]");
    permanentNodes.forEach(function(element){
      var oldElement = newBody.querySelector(`#${element.id}[data-turbolinks-permanent]`)
      oldElement.parentNode.replaceChild(element, oldElement);
    });

    // Remove all the current nodes from our element.
    while( this.element.firstChild ) { this.element.removeChild( this.element.firstChild ); }

    // When we're sending a new partial, which is a full replacement of our
    // element & not just a group of children.
    if( newBody.childElementCount === 1 && newBody.firstElementChild.dataset.realtimePartialKey === this.data.get("key") ){
      while( newBody.firstElementChild.firstChild ) { this.element.appendChild( newBody.firstElementChild.firstChild ); }
    } else {
      // Append the new nodes.
      while( newBody.firstChild ) { this.element.appendChild( newBody.firstChild ); }
    }
  }

  // From: https://stackoverflow.com/a/42658543/445724
  // using .innerHTML= is risky. Instead we need to convert the HTML received
  // into elements, then append them.
  // It's wrapped in a <template> tag to avoid invalid (e.g. a block starting with <tr>)
  // being mutated inappropriately.
  _parseHTMLResponse(responseHTML){
    let parser = new DOMParser();
    let responseDocument = parser.parseFromString( `<template>${responseHTML}</template>` , 'text/html');
    let parsedHTML = responseDocument.head.firstElementChild.content;
    return parsedHTML;
  }

  windowScrolledToBottom() {
    if ((window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 50)) {
      return true
    } else {
      return false
    }
  }

  scrollToBottom() {
    // Replace with something pure JS later that smoothsrolls
    // window.scroll({left: 0, top: document.body.scrollHeight, behavior: 'smooth'});
    $("html, body").animate({ scrollTop: document.body.scrollHeight }, 800) 
  }

  enableAutoScroll() {
    if (this.windowScrolledToBottom()) {
      this.autoscrollTarget.classList.add('bg-success');
      this.autoscrollTarget.classList.remove('bg-dark');
      this.autoscrollTarget.innerHTML = "<i class=\"fas fa-arrow-down mr-1\"></i> Autoscroll Enabled";
    } else {
      this.autoscrollTarget.classList.add('bg-dark');
      this.autoscrollTarget.classList.remove('bg-success');
      this.autoscrollTarget.innerHTML = "<i class=\"fas fa-arrow-down mr-1\"></i> Scroll to Bottom";
    }
  }

  debounce(fn, wait, immediate){
    let timeout;
    let self = this;
    return function() {
      let context = self, args = arguments;
      let later = () => {
        timeout = null;
        if (!immediate) fn.apply(context, args);

      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) fn.apply(context, args);

    };
  }

  refreshCheck() {
    if (sessionStorage.getItem('lastPage') === location.href) {
      // This likely means the page was refreshed
      console.log('Page was refreshed');
      this.openRefresh((button) => {
        button.on("click", () => { this.closeRefresh() });
      });
    } else {
      // This means the page was not refreshed (e.g., navigated to from another page)
      console.log('Page was not refreshed');
    }

    sessionStorage.setItem('lastPage', location.href);
  }

  openRefresh(buttonCallback) {
    buttonCallback($("#refresh-button-action"));

    $("#refresh").toggleClass("d-none", false);
  }

  closeRefresh() {
    $("#refresh").toggleClass("d-none", true);

    return true;
  }

}
